import React from "react";
import {
  BlockStack,
  Box,
  Card,
  InlineGrid,
  InlineStack,
  Layout,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  SkeletonThumbnail,
  Text,
} from "@shopify/polaris";

export function CommonSkeletonPage() {
  return (
    <SkeletonPage primaryAction>
      <Layout>
        <Layout.Section>
          <Card sectioned>
            <SkeletonBodyText />
          </Card>
          <br />
          <Card sectioned>
            <SkeletonBodyText />
          </Card>
          <br />
          <Card sectioned>
            <SkeletonBodyText />
          </Card>
          <br />
          <Card sectioned>
            <SkeletonBodyText />
          </Card>
          <br />
          <Card sectioned>
            <SkeletonBodyText />
          </Card>
        </Layout.Section>
        <Layout.Section secondary>
          <Card>
            <SkeletonBodyText lines={2} />
            <br />
            <SkeletonBodyText lines={1} />
          </Card>
          <br />
          <Card subdued>
            <SkeletonBodyText lines={2} />
            <br />
            <SkeletonBodyText lines={2} />
          </Card>
          <br />
          <Card subdued>
            <SkeletonBodyText lines={2} />
            <br />
            <SkeletonBodyText lines={2} />
          </Card>
          <br />
          <Card subdued>
            <SkeletonBodyText lines={2} />
            <br />
            <SkeletonBodyText lines={2} />
          </Card>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );
}

const ReadymadeDesignIcons = () => {
  const icons = [];

  for (let i = 0; i < 3; i++) {
    icons.push(
      <Card key={i}>
        <BlockStack gap={300}>
          <div className="readymade-inlinegrid--sleletonBodyText">
            <SkeletonBodyText lines={1} />
          </div>
          <Card>
            <div className="readymade-inlinegrid--icons">
              <SkeletonThumbnail size="extraSmall" />
              <SkeletonThumbnail size="extraSmall" />
              <SkeletonThumbnail size="extraSmall" />
              <SkeletonThumbnail size="extraSmall" />
            </div>
          </Card>
        </BlockStack>
      </Card>
    );
  }
  return icons;
};

const OtherApps = () => {
  return (
    <>
      <Card>
        <div className="otherApp-container">
          <div className="otherApp-content">
            <SkeletonThumbnail size="large" />
            <br />
            <div className="other-app--name">
              <SkeletonBodyText lines={1} />
            </div>
            <br />
            <div className="other-app-textContent">
              <SkeletonBodyText lines={3} />
            </div>
          </div>
          <div className="otherApp-freeplan">
            <div className="otherApp-freeplan--skeleton">
              <SkeletonBodyText lines={1} />
            </div>
          </div>
          <div className="otherApp-freeplan-btn">
            <SkeletonDisplayText size="small" />
          </div>
        </div>
      </Card>
    </>
  );
};

export function DashboardSkeleton({ isEnabled }) {
  const hideReview = localStorage.getItem("SocialBarReview");
  const hideOtherApps = localStorage.getItem("SocialBarOtherApps");
  return (
    <SkeletonPage>
      <Layout>
        <Layout.Section>
          <BlockStack gap="500">
            {isEnabled && (
              <Card>
                <div className="dash-banner-skeleton">
                  <div className="dash-banner-enable">
                    <SkeletonThumbnail size="extraSmall" />
                    <SkeletonBodyText lines={1} />
                  </div>
                  <br />
                  <div className="dash-banner-message">
                    <SkeletonBodyText lines={1} />
                  </div>
                  <br />
                </div>
                <SkeletonDisplayText size="small" />
              </Card>
            )}
            <div className="homepage-inlinegrid">
              <div className="left">
                <Card>
                  <BlockStack gap={200}>
                    <div className="dash-addicon--skeletondisplaytext">
                      <InlineStack align="center" gap={100}>
                        <SkeletonDisplayText size="small" />
                        <SkeletonDisplayText size="small" />
                      </InlineStack>
                    </div>
                    <BlockStack gap={300}>
                      <div className="dash-addicon-box">
                        <Box borderColor="border" borderWidth="025" padding={300} borderRadius="200">
                          <div className="dash-addicon--icon">
                            <SkeletonBodyText lines={1} />
                            <SkeletonThumbnail size="extraSmall" />
                            <div className="skeletonbodytext">
                              <SkeletonBodyText lines={1} />
                            </div>
                          </div>
                          <div className="dash-addicon--remove">
                            <SkeletonBodyText lines={1} />
                            <SkeletonThumbnail size="extraSmall" />
                          </div>
                        </Box>
                      </div>

                      <div className="dash-addicon-box">
                        <Box borderColor="border" borderWidth="025" padding={300} borderRadius="200">
                          <div className="dash-addicon--icon">
                            <SkeletonBodyText lines={1} />
                            <SkeletonThumbnail size="extraSmall" />
                            <div className="skeletonbodytext">
                              <SkeletonBodyText lines={1} />
                            </div>
                          </div>
                          <div className="dash-addicon--remove">
                            <SkeletonBodyText lines={1} />
                            <SkeletonThumbnail size="extraSmall" />
                          </div>
                        </Box>
                      </div>
                    </BlockStack>

                    <div className="dash-addicon--btn">
                      <InlineStack gap={200} align="center">
                        <div className="dash-addicon--addicon">
                          <SkeletonDisplayText size="small" />
                        </div>
                      </InlineStack>
                    </div>
                  </BlockStack>
                </Card>
              </div>
              <div className="main right">
                <Card>
                  <div className="macTheme-livePreview--skeleton">
                    <div className="macTheme-livePreview--text"></div>
                    <div className="social-view">
                      <div className="social-example header">
                        <div className="skeletonbodytext">
                          <SkeletonBodyText lines={1} />
                        </div>
                      </div>
                      <div className="social-example footer">
                        <div className="skeletonbodytext">
                          <SkeletonBodyText lines={1} />
                        </div>
                      </div>
                    </div>
                  </div>
                </Card>
              </div>
            </div>

            {/* <div className="homepage-inlinegrid"> */}
            {/* <div className="left"> */}
            <Card>
              <InlineStack align="space-between" blockAlign="center">
                <div className="readymade-design--skeleton">
                  <SkeletonBodyText lines={1} />
                </div>
                <div className="readymade-design--badgeSkeleton">
                  <SkeletonDisplayText />
                </div>
              </InlineStack>
            </Card>
            {/* </div> */}
            {/* <div className="right">
                <Card>
                  <div className="readymade-design--addIcon">
                    <SkeletonBodyText lines={1} />
                    <SkeletonDisplayText />
                  </div>
                </Card>
              </div> */}
            {/* </div> */}

            <div className="readymade-inlinegrid">
              <InlineGrid columns={3} gap={500}>
                <ReadymadeDesignIcons />
              </InlineGrid>
            </div>

            {!hideReview && (
              <Card>
                <div className="social-other-apps">
                  <InlineStack align="space-between">
                    <div className="social--other-apps--title">
                      <SkeletonBodyText lines={1} />
                    </div>
                    <div className="social-discard">
                      <SkeletonBodyText lines={1} />
                    </div>
                  </InlineStack>
                </div>
                <br />
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "1rem",
                    }}
                  >
                    <div className="hideReview--skeleBodySecond">
                      <SkeletonBodyText lines={2} />
                    </div>
                    <div style={{ width: "8rem", marginTop: "1rem" }}>
                      <SkeletonDisplayText />
                    </div>
                  </div>
                  <div>
                    <div style={{ marginRight: "4rem" }}>
                      <SkeletonThumbnail size="large" />
                    </div>
                  </div>
                </div>
              </Card>
            )}

            {!hideOtherApps && (
              <Card>
                <div className="social-other-apps">
                  <InlineStack align="space-between">
                    <div className="social--other-apps--title">
                      <SkeletonBodyText lines={1} />
                    </div>
                    <div className="social-discard">
                      <SkeletonBodyText lines={1} />
                    </div>
                  </InlineStack>
                </div>
                <br />
                <div className="otherApp">
                  <OtherApps />
                  <OtherApps />
                  <OtherApps />
                </div>
              </Card>
            )}
          </BlockStack>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );
}

const PricePage = ({ elements, isPremium }) => {
  return (
    <>
      <Layout>
        <Layout.Section>
          <BlockStack gap="500">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                paddingTop: "16px",
                height: process.env.ISFREEAPP == 1 ? "100%" : "43.5rem",
              }}
            >
              <Card>
                <div style={{ width: "18rem" }}>
                  <div>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                      <div style={{ width: !isPremium ? "3rem" : "5rem" }}>
                        <SkeletonBodyText lines={1} />
                      </div>
                      {!isPremium ? (
                        <div className="pricing-SkeletonThumbnail">
                          <SkeletonThumbnail size="extraSmall" />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <br />
                    <div className="pricing-SkeletonBodyText">{/* <SkeletonBodyText lines={1} /> */}</div>
                    <br />
                    <br />
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      <div style={{ width: "3rem" }}>
                        <SkeletonBodyText lines={1} />
                      </div>
                      {isPremium ? (
                        <div style={{ width: "8rem" }}>
                          <SkeletonBodyText lines={1} />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <br />
                    {isPremium ? (
                      <div
                        className="pricing-SkeletonBodyText premium-page--skeleton"
                        style={{ display: "flex", gap: "0.5rem", alignItems: "center" }}
                      >
                        <div className="premium-skeleton">
                          <SkeletonBodyText lines={1} />
                        </div>
                        <SkeletonBodyText lines={1} />
                      </div>
                    ) : (
                      ""
                    )}
                    <br />
                    {isPremium ? (
                      <div className="premium-skeleton--page">
                        <SkeletonBodyText lines={1} />
                      </div>
                    ) : (
                      ""
                    )}
                    <br />
                    <SkeletonBodyText lines={1} />
                    <br />
                    <div>{elements}</div>
                    <br />
                    {process.env.ISFREEAPP == 1 ? (
                      <div className="pricing-SkeletonDisplayText">
                        <SkeletonDisplayText size="medium" />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </Card>
            </div>
          </BlockStack>
        </Layout.Section>
      </Layout>
    </>
  );
};

export function PricingSkeleton() {
  const elements = [];
  for (let i = 0; i < 9; i++) {
    elements.push(
      <div key={`div-${i}`} style={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
        <div className="pricing-SkeletonThumbnail">
          <SkeletonThumbnail size="extraSmall" />
        </div>
        <div style={{ width: "10rem" }}>
          <SkeletonBodyText lines={1} />
        </div>
      </div>
    );
    if (i !== 8) {
      elements.push(<br key={`br-${i}`} />);
    }
  }
  let isPremium = true;
  return (
    <>
      <SkeletonPage primaryAction>
        <div style={{ display: "flex", justifyContent: "center", gap: "1rem", flexWrap: "wrap" }}>
          <PricePage elements={elements} />
          {process.env.ISFREEAPP == 0 ? <PricePage elements={elements} isPremium={isPremium} /> : ""}
        </div>
      </SkeletonPage>
    </>
  );
}
